<template>
  <div>
    <el-row class="movie-list">
      <el-col :md="24">
        <el-card :body-style="{ padding: '0px' }" class="card">
          <div slot="header" class="clearfix">
            <el-row>
              <el-col :md="1">
                <el-avatar>
                  <el-image :src="user.avatarUrl" />
                </el-avatar>
              </el-col>
              <el-col :md="23">
                <router-link target="_blank" :to="`/user/${user.userId}`">
                  <span>{{ user.screenName }}</span>
                </router-link>
                <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'" />
                <el-button
                  type="danger"
                  size="mini"
                  :icon="followButton.icon"
                  @click="followUser(user.userId)"
                >
                  <span>{{ followButton.text }}</span>
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-message"
                  @click="sendMessage(user.userId)"
                >
                  <span>发消息</span>
                </el-button>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24" class="movie-list">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane name="following">
            <span slot="label">
              Ta 的关注<el-badge :value="this.user.following" :max="9999" class="item" type="warning" />
            </span>
            <div v-if="activeName === 'following'">
              <el-col v-for="(user, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
                <user-avatar-card :user-avatar="user" />
              </el-col>
            </div>
          </el-tab-pane>
          <el-tab-pane name="follower">
            <span slot="label">
              Ta 的粉丝<el-badge :value="this.user.follower" :max="9999" class="item" type="warning" />
            </span>
            <div v-if="activeName === 'follower'">
              <el-col v-for="(user, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
                <user-avatar-card :user-avatar="user" />
              </el-col>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="24" class="pagination">
        <el-pagination
          background
          :small="screenWidth <= 768"
          hide-on-single-page
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="totalSize"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChange"
          @next-click="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <el-row v-if="dataList.length === 0" class="not-result">
      <el-col :span="12" :offset="6">
        <img src="@/assets/img/icon/not-collection.png">
        <div>该用户还没关注任何人呢</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UserAvatarCard from '@/components/card/UserAvatarCard'

import { getUserInfo, getUserFollowing, getUserFollower, checkRelation, followUser, unfollowUser } from '@/api/user'

export default {
  name: 'Home',
  components: { UserAvatarCard },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      user: null,
      userId: null,
      followButton: {
        icon: 'el-icon-plus',
        text: '关注'
      },
      activeName: 'following',
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      showEmpty: true
    }
  },
  watch: {
    $route() {
      this.$router.go()
    }
  },
  created() {
    this.userId = this.$route.params.id
    getUserInfo(this.userId).then(res => {
      if (res.code === 0) {
        this.user = res.data
        const path = this.$route.path
        if (path.endsWith('following')) {
          this.activeName = 'following'
          document.title = this.user.screenName + '的关注'
        } else if (path.endsWith('follower')) {
          this.activeName = 'follower'
          document.title = this.user.screenName + '的粉丝'
        } else {
          document.title = this.user.screenName + '的个人主页'
        }
        this.getData()
      }
    })
    checkRelation(this.userId).then(res => {
      if (res.code === 0) {
        if (res.data) {
          this.followButton.text = '已关注'
          this.followButton.icon = 'el-icon-check'
        }
      }
    })
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.getData()
      /* if (this.activeName === 'following') {
        getUserFollowing(this.userId).then(res => {
          if (res.code === 0) {
            this.dataList = res.data
          }
        })
      } else if (this.activeName === 'follower') {
        getUserFollower(this.userId).then(res => {
          if (res.code === 0) {
            this.dataList = res.data
          }
        })
      }*/

      // 回到顶部
      scrollTo(0, 0)
    },
    tabClick(tab) {
      this.activeName = tab.name
      this.goToTab(this.activeName)
    },
    goToTab(activeName) {
      const path = '/user/' + this.userId + '/' + activeName
      if (this.$route.path === path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    getData() {
      this.dataList = []
      if (this.activeName === 'following') {
        getUserFollowing(this.userId).then(res => {
          if (res.code === 0) {
            this.dataList = res.data
          }
        })
      } else if (this.activeName === 'follower') {
        getUserFollower(this.userId).then(res => {
          if (res.code === 0) {
            this.dataList = res.data
          }
        })
      }
    },
    followUser(userId) {
      if (this.followButton.text === '关注') {
        followUser(userId).then(res => {
          if (res.code === 0) {
            this.followButton.text = '已关注'
            this.followButton.icon = 'el-icon-check'
          }
        })
      } else {
        unfollowUser(userId).then(res => {
          if (res.code === 0) {
            this.followButton.text = '关注'
            this.followButton.icon = 'el-icon-plus'
          }
        })
      }
    },
    sendMessage(userId) {
      console.log('发送消息')
    }
  }
}
</script>

<style scoped>
.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.pagination {
  text-align: center;
  padding: 10px;
}

/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.item {
  margin-top: 10px;
  margin-right: 40px;
}
</style>
